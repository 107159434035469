// COLORS
$back: dodgerblue; // Change the color if you want

body {
    counter-reset: spinnerCount;
}

.cell {
    //width: 25%;
    position: relative;

    .wrapper {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        background: $white;
        z-index: 99999999999;

        img{
            margin-bottom: 20px;
        }
    }

    .internal-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100px;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 999;

        img{
            margin-bottom: 20px;
        }
    }
}

// SPINNERS STYLES
.spinner {
    // Common styles
    width: 4rem;
    height: 4rem;

    &.spinner2{
        border: 3px solid transparent;
        border-top-color: rgba($purple, 1);
        border-bottom-color: rgba($purple, 1);
        border-radius: 50%;
        animation: rotation .8s ease infinite;
    }

    &.spinner5 {
        margin-top: 2rem; // just for centering the demo
        overflow: hidden;
        position: relative;
        height: .5rem;
        background: rgba($blue, .25);

        &::before {
            content: '';
            position: absolute;
            left: -130%;
            width: 100%;
            height: 100%;
            background: rgba($blue, .5);
            animation: progress 1.6s linear infinite;
        }

    }

}


// ANIMATIONS
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes flip {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    }
}

@keyframes progress {
    0% {
        left: -130%;
        background: rgba($blue, .5);
    }

    50% {
        left: 130%;
        background: rgba($blue, .5);
    }

    51% {
        background: rgba($blue, .5);
    }

    100% {
        background: rgba($blue, .5);
    }
}

@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -50%) scale(1.0);
        opacity: 0;
    }
}

@keyframes pulse2 {

    0%,
    100% {
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }

    50% {
        transform: translate(-50%, -50%) scale(1.0);
        opacity: 0;
    }
}

@keyframes flipWalker {
    0% {
        transform: translate(0, 0) rotateX(0) rotateY(0);
    }

    25% {
        transform: translate(100%, 0) rotateX(0) rotateY(180deg);
    }

    50% {
        transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
    }

    75% {
        transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
    }

    100% {
        transform: translate(0, 0) rotateX(0) rotateY(360deg);
    }
}