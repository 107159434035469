.btn.btn-custom {
    border-radius: 500px;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: $vag;
    width: 100%;
    text-shadow: 0 2px 3px #610095;
    font-weight: bold;
    box-shadow: 0 16px 30px -8px rgba(0, 116, 188, 0.39);
    position: relative;
    outline: none;
    display: inline-block;
    vertical-align: middle;
    z-index: 1;
    background-color: $blueDark;
    color: $white !important;
    padding: 15px 25px;

    span{
        display: block;
        position: relative;
        top: -3px;
    }

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: -4px;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(to bottom, #0a9dff, #0464ff);
        z-index: -1;
        border-radius: inherit;
    }

    &:hover{
        padding-bottom: 15px;

        span{
            top: 0;
        }

        &:before{
            top: inherit;
            bottom: -1px;
        }
    }

    &.btn-small{
        padding: 8px 18px; 
    }

    &.btn-little-small{
        padding: 4px 10px; 
        width: fit-content;
        span{
            font-size: 12px;
        }
    }

    &.btn-outline{
        background: transparent;
        color: $blue !important;
        border: 1px solid $blue;
        box-shadow: none;
        text-shadow: none;

        span{
            top: 0;
        }

        &:before{
            content: none;
        }

        &:hover{
            //background: $blue;
            background-image: linear-gradient(to bottom, #0a9dff, #0464ff);
            color: $white !important;
        }
    }

    &.btn-purple{
        background: #8112BD;

        &:before{
            background: #A600FF;
        }
    }

    &.btn-red{
        background: #CA191A;

        &:before{
            background: #EF3F40;
        }
    }

    &.btn-blue{
        background: #005B93;

        &:before{
            background: #0074BC;
        }
    }

    &.btn-yellow{
        background: #C87C20;

        &:before{
            background: #FBA640;
        }
    }

    @include md{
        font-size: 12px;
        line-height: 12px;
    }
}