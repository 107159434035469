.customTour{
    .introjs-tooltip-header{
        h1{
            &.introjs-tooltip-title{
                font-style: normal;
                font-weight: bold;
                font-size: 26px;
                line-height: 32px;
                font-family: $vag;
            }
        }
    }

    .introjs-tooltiptext{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
    }

    .introjs-tooltipbuttons{
        border: none;
        padding: 0;

        .introjs-button{
            color: $white;
            font-weight: normal;
            border: none;
            border-radius: 0px;
            text-shadow: none;
            
            &:focus{
                outline: none;
                box-shadow: none;
            }
        }

        .introjs-prevbutton{
            background: $green;
        }

        .introjs-nextbutton{
            background: $purple;
        }
    }
}