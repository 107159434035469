@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

@font-face {
  font-family: 'VAG Rounded';
  src: url("../assets/fonts/VAG-Rounded-Bold.eot");
  /* IE9 Compatibility Modes */
  src: url("../assets/fonts/VAG-Rounded-Bold.eot?") format("eot"), url("../assets/fonts/VAG-Rounded-Bold.woff") format("woff"), url("../assets/fonts/VAG-Rounded-Bold.ttf") format("truetype"), url("../assets/fonts/VAG-Rounded-Bold.svg/#svgGraublauweb") format("svg");
  /* Legacy iOS */
}

$open: 'Open Sans', sans-serif;
$vag: 'VAG Rounded';

$blue: #0074BC;
$blueDark: #004067;
$white: #ffffff;
$green: #00B7BD;
$greenLight: #e9f9f9;
$purple: #A600FF;
$red: #EF3F40;

$border: 48px;

.vag{
  font-family: $vag !important;
}

.open-sans{
  font-family: $open !important;
}

.cBlue{
  color: $blue !important;
}

.bBlue{
  background-color: $blue !important;
}

.cGreenLight{
  color: $greenLight !important;
}

.bGreenLight{
  background-color: $greenLight !important;
}

@mixin xs {
  @media (max-width: 320px) { @content }
}

@mixin sm {
  @media (max-width: 576px) { @content }
}

@mixin md {
  @media (max-width: 768px) { @content }
}

@mixin lg {
  @media (max-width: 1024px) { @content }
}

@mixin xl {
  @media (max-width: 1440px) { @content }
}

@mixin xxl {
  @media (max-width: 1920px) { @content }
}

@mixin md-landscaping{
  @media only screen 
  and (min-device-width: 601px) 
  and (max-device-width: 1280px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {
    @content
  }
}
@mixin md-landscaping{
  @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 600px)
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: landscape) {
      @content
  }
}