@import "variables";
@import "avatar";
@import "buttons";
@import "tour";
@import "loading";

.margin-auto{
  margin: 0 auto;
}

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: $white;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination-bullet {
    margin: 0 1px !important;
  }

  .swiper-pagination-bullet-active {
    background: #ffffff !important;
  }

  /* Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/ Changed by Afshin Mehrabani */
  /* overrides extra padding on button elements in Firefox */
  @-webkit-keyframes introjspulse {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    25% {
      transform: scale(0);
      opacity: 0.1;
    }

    50% {
      transform: scale(0.1);
      opacity: 0.3;
    }

    75% {
      transform: scale(0.5);
      opacity: 0.5;
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes introjspulse {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    25% {
      transform: scale(0);
      opacity: 0.1;
    }

    50% {
      transform: scale(0.1);
      opacity: 0.3;
    }

    75% {
      transform: scale(0.5);
      opacity: 0.5;
    }

    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  .introjs-overlay {
    position: absolute;
    box-sizing: content-box;
    z-index: 999999;
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  .introjs-showElement {
    z-index: 9999999 !important;
  }

  tr.introjs-showElement>td {
    z-index: 9999999 !important;
    position: relative;
  }

  tr.introjs-showElement>th {
    z-index: 9999999 !important;
    position: relative;
  }

  .introjs-disableInteraction {
    z-index: 99999999 !important;
    position: absolute;
    background-color: #ffffff;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .introjs-relativePosition {
    position: relative;
  }

  .introjs-helperLayer {
    box-shadow: rgba(0, 116, 188, 0.71) 0px 0px 1px 2px, rgba(0, 116, 188, 0.46) 0px 0px 0px 5000px !important;
  }

  .introjs-helperLayer {
    box-sizing: content-box;
    position: absolute;
    z-index: 9999998;
    border-radius: 4px;
    transition: all 0.3s ease-out;
    box-shadow: rgba(0, 116, 188, 0.71) !important;
  }

  .introjs-helperLayer * {
    box-sizing: content-box;
  }

  .introjs-helperLayer *:before {
    box-sizing: content-box;
  }

  .introjs-helperLayer *:after {
    box-sizing: content-box;
  }

  .introjs-tooltipReferenceLayer {
    font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
    box-sizing: content-box;
    position: absolute;
    visibility: hidden;
    z-index: 100000000;
    background-color: transparent;
    transition: all 0.3s ease-out;
  }

  .introjs-tooltipReferenceLayer * {
    font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
  }

  .introjs-helperNumberLayer {
    font-family: "Helvetica Neue", Inter, ui-sans-serif, "Apple Color Emoji", Helvetica, Arial, sans-serif;
    color: #9e9e9e;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .introjs-arrow {
    border: 5px solid transparent;
    content: "";
    position: absolute;
  }

  .introjs-arrow.top {
    top: -10px;
    left: 10px;
    border-bottom-color: #ffffff;
  }

  .introjs-arrow.top-right {
    top: -10px;
    right: 10px;
    border-bottom-color: #ffffff;
  }

  .introjs-arrow.top-middle {
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: #ffffff;
  }

  .introjs-arrow.right {
    right: -10px;
    top: 10px;
    border-left-color: #ffffff;
  }

  .introjs-arrow.right-bottom {
    bottom: 10px;
    right: -10px;
    border-left-color: #ffffff;
  }

  .introjs-arrow.bottom {
    bottom: -10px;
    left: 10px;
    border-top-color: #ffffff;
  }

  .introjs-arrow.bottom-right {
    bottom: -10px;
    right: 10px;
    border-top-color: #ffffff;
  }

  .introjs-arrow.bottom-middle {
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
    border-top-color: #ffffff;
  }

  .introjs-arrow.left {
    left: -10px;
    top: 10px;
    border-right-color: #ffffff;
  }

  .introjs-arrow.left-bottom {
    left: -10px;
    bottom: 10px;
    border-right-color: #ffffff;
  }

  .introjs-tooltip {
    box-sizing: content-box;
    position: absolute;
    visibility: visible;
    background-color: #ffffff;
    min-width: 250px;
    max-width: 300px;
    border-radius: 10px;
    box-shadow: 0 3px 30px rgba(33, 33, 33, 0.3);
    transition: opacity 0.1s ease-out;
  }

  .introjs-tooltiptext {
    color: #002d49;
    font-size: 12px;
    padding: 20px;
  }

  .introjs-tooltip-title {
    color: $blue;
    font-size: 26px;
    margin: 0;
    padding: 0;
    font-weight: bold;
    float: left;
    line-height: 32px;
  }

  .introjs-tooltip-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
  }

  .introjs-tooltip-header:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  .introjs-tooltipbuttons {
    display: flex;
    justify-content: space-around;
    text-align: right;
    white-space: nowrap;
  }

  .introjs-tooltipbuttons:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  .introjs-button {
    box-sizing: content-box;
    position: relative;
    overflow: visible;
    display: inline-block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    background-color: #a600ff;
    zoom: 1;
    *display: inline;
    width: 38%;
    text-align: center;
  }

  .introjs-button:hover {
    outline: none;
    text-decoration: none;
    background-color: #a600ff;
    color: #ffffff;
    font-weight: bold;
  }

  .introjs-button:focus {
    outline: none;
    text-decoration: none;
    background-color: #a600ff;
    color: #ffffff;
  }

  .introjs-button:active {
    outline: none;
    text-decoration: none;
    background-color: #a600ff;
    color: #ffffff;
  }

  .introjs-button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  .introjs-skipbutton {
    display: none;
    box-sizing: content-box;
    color: #616161;
    float: right;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    padding: 7px 10px;
  }

  .introjs-skipbutton:hover,
  .introjs-skipbutton:focus {
    color: #212121;
    outline: none;
    text-decoration: none;
  }

  .introjs-prevbutton {
    width: 118px;
    float: left;
    background-color: #00b7bd !important;
    border-bottom-left-radius: 10px !important;
  }

  .introjs-nextbutton {
    width: 118px;
    float: right;
    border-bottom-right-radius: 10px !important;
  }

  .introjs-disabled {
    color: #ffffff;
    box-shadow: none;
    cursor: default;
    background-color: #aedbdd !important;
    background-image: none;
    text-decoration: none;
    width: 35%;
  }

  .introjs-disabled:hover,
  .introjs-disabled:focus {
    color: #ffffff;
    box-shadow: none;
    cursor: default;
    background-color: #aedbdd !important;
    background-image: none;
    text-decoration: none;
  }

  .introjs-hidden {
    display: none;
  }

  .introjs-bullets {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .introjs-bullets ul {
    box-sizing: content-box;
    clear: both;
    margin: 0 auto 0;
    padding: 0;
    display: inline-block;
  }

  .introjs-bullets ul li {
    box-sizing: content-box;
    list-style: none;
    float: left;
    margin: 0 2px;
  }

  .introjs-bullets ul li a {
    transition: width 0.1s ease-in;
    box-sizing: content-box;
    display: block;
    width: 6px;
    height: 6px;
    background: #ccc;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
  }

  .introjs-bullets ul li a:hover,
  .introjs-bullets ul li a:focus {
    width: 15px;
    background: #999;
    text-decoration: none;
    outline: none;
  }

  .introjs-bullets ul li a.active {
    width: 15px;
    background: #999;
  }

  .introjs-progress {
    box-sizing: content-box;
    overflow: hidden;
    height: 10px;
    margin: 10px;
    border-radius: 4px;
    background-color: #e0e0e0;
  }

  .introjs-progressbar {
    box-sizing: content-box;
    float: left;
    width: 0%;
    height: 100%;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    background-color: #08c;
  }

  .introjsFloatingElement {
    position: absolute;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%;
  }

  .introjs-fixedTooltip {
    position: fixed;
  }

  .introjs-hint {
    box-sizing: content-box;
    position: absolute;
    background: transparent;
    width: 20px;
    height: 15px;
    cursor: pointer;
  }

  .introjs-hint:focus {
    border: 0;
    outline: 0;
  }

  .introjs-hint:hover>.introjs-hint-pulse {
    border: 5px solid rgba(60, 60, 60, 0.57);
  }

  .introjs-hidehint {
    display: none;
  }

  .introjs-fixedhint {
    position: fixed;
  }

  .introjs-hint-pulse {
    box-sizing: content-box;
    width: 10px;
    height: 10px;
    border: 5px solid rgba(60, 60, 60, 0.27);
    border-radius: 30px;
    background-color: rgba(136, 136, 136, 0.24);
    z-index: 10;
    position: absolute;
    transition: all 0.2s ease-out;
  }

  .introjs-hint-no-anim .introjs-hint-dot {
    -webkit-animation: none;
    animation: none;
  }

  .introjs-hint-dot {
    box-sizing: content-box;
    border: 10px solid rgba(146, 146, 146, 0.36);
    background: transparent;
    border-radius: 60px;
    height: 50px;
    width: 50px;
    -webkit-animation: introjspulse 3s ease-out;
    animation: introjspulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    position: absolute;
    top: -25px;
    left: -25px;
    z-index: 1;
    opacity: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-family: $vag;
  }

  h1,
  .h1 {
    font-size: 2.5rem;
    font-size: 5rem;
    line-height: 4.3rem;
    color: $blue;
  }

  h2,
  .h2 {
    font-size: 2rem;
  }

  h3,
  .h3 {
    font-size: 1.75rem;
  }

  h4,
  .h4 {
    font-size: 1.5rem;
  }

  h5,
  .h5 {
    font-size: 1.25rem;
  }

  h6,
  .h6 {
    font-size: 1rem;
  }

  .title_h1 {
    color: #ef3f40;
    font-size: 36px;
    font-weight: bold;
    line-height: 32px;
  }

  .title_reset_pass {
    color: #ef3f40;
    font-size: 26px;
    font-weight: bold;
    line-height: 32px;
  }

  /* First Step */
  .title_first_step {
    color: #ef3f40;
    font-size: 26px;
    font-weight: bold;
    line-height: 22px;
  }

  .text-gender {
    color: $blue;
    font-size: 20px;
  }

  .title_h2 {
    color: #ef3f40;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    padding: 12px 0;
  }

  @media (min-width: 576px) {
    .title_h2 {
      font-size: 26px;
      line-height: 32px;
    }
  }

  /* Custom Utilities -------------------- */
  .text-brown {
    color: #b08443;
  }

  .text-black {
    color: black;
  }

  .text-blue {
    color: $blue;
  }

  .text-blue-2 {
    color: #3F5376;
  }

  .text-blue-dark {
    color: #004067;
  }

  .text-title-modal-danger {
    color: #ec3738 !important;
  }

  .text-yellow-1 {
    color: #fba640 !important;
  }

  .text-purple-1 {
    color: #a600ff !important;
  }

  .text-danger-1 {
    color: #ef3f40 !important;
  }

  .text-cyan-sky-1 {
    color: #00B7BD !important;
  }

  .bg-grey {
    background-color: black;
  }

  .fs-16 {
    font-size: 16px !important;
  }

  /* Base Styles -------------------- */
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .container {
    box-sizing: border-box;
  }

  body {
    font-family: $open;
  }

  button, .button, .btn{
    font-family: $vag;
  }

  body:before {
    content: "xs";
    display: none;
    visibility: hidden;
  }

  @media (min-width: 576px) {
    body:before {
      content: "sm";
    }
  }

  @media (min-width: 768px) {
    body:before {
      content: "md";
    }
  }

  @media (min-width: 992px) {
    body:before {
      content: "lg";
    }
  }

  @media (min-width: 1200px) {
    body:before {
      content: "xl";
    }
  }

  label {
    font-weight: normal;
    font-size: 16px;
    font-weight: normal;
    color: $blue;
    margin-bottom: 0;
  }

  @media (min-width: 576px) {
    label {
      font-size: 18px;
      line-height: 28px;
    }
  }

  input.form-control {
    border-radius: 20px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #dfe2e6;
    background-color: #ffffff;
  }

  .remeberMe {
    margin-top: 32px;
    /*display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;*/
    font-weight: normal;
    font-size: 15px;

    .form-check{
      padding-left: 20px;
    }

    @include md{
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .remeberMe label {
    color: #9c9c9c !important;
    font-size: 15px;
    position: relative;
    top: 0;
    cursor: pointer;
  }

  .remeberMe a {
    text-decoration: none;
    color: #ef3f40;
    font-weight: normal;
    font-size: 15px;
  }

  .remeberMe input{
    margin-top: 8px;
  }

  .crearCuenta {
    text-decoration: none;
    color: #9c9c9c;
  }

  .crearCuenta:hover {
    text-decoration: none;
    color: #9c9c9c;
  }

  .password {
    padding-right: 30px;
  }

  .i-email {
    background: url("../assets/img/icons/i-email.png");
    background-repeat: no-repeat;
    background-position: 4% center;
    background-size: 20px;
    padding-left: 40px;
  }

  .i-pass {
    background: url("../assets/img/icons/i-pass.png");
    background-repeat: no-repeat;
    background-position: 4% center;
    background-size: 20px;
    padding-left: 40px;
  }

  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: $blue;
    outline: 0;
    box-shadow: none !important;
  }

  .bg_img_login {
    width: 100%;
    height: 220px;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    position: absolute;
    z-index: 0;
    top: 0;
    -webkit-filter: drop-shadow(0px 2px 38px rgba(134, 159, 174, 0.480578));
    filter: drop-shadow(0px 2px 38px rgba(134, 159, 174, 0.480578));
    max-width: 1440px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-size: cover;
  }

  @media (min-width: 768px) {
    .bg_img_login {
      height: 537px;
    }
  }

  .box_login_row {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1;
    position: relative;
  }

  @media (min-width: 768px) {
    .box_login_row {
      align-content: center;
    }
  }

  .box_login_row .box_login_container {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 936px;
    border-radius: 40px;
    overflow: hidden;

    input[type="password"]{
      border: solid 1px $blue;
    }
  }

  .content_login {
    padding: 50px 29px 10px 29px;
  }

  @media (min-width: 992px) {
    .content_login {
      padding: 51px 58px 10px 58px;
    }
  }

  .content_login .logo img {
    max-width: 130px;
  }

  @media (min-width: 576px) {
    .content_login .logo img {
      max-width: 170px;
    }
  }

  @media (min-width: 992px) {
    .content_login .logo img {
      max-width: 100%;
    }
  }

  .main_login {
    background-color: $greenLight;
  }

  .main_login .slider {
    position: relative;
    height: 230px;
  }

  @media (min-width: 576px) {
    .main_login .slider {
      height: 100%;
    }
  }

  .main_login .slider:before {
    content: '';
    background-image: linear-gradient(41deg, rgba(0, 116, 187, 0.57) 2%, rgba(0, 116, 187, 0) 61%);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .main_login .slider img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
  }

  .footer_cstm p {
    color: $blue;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
  }

  .icon_input {
    position: relative;
  }

  .icon_input .toggle-password {
    background: url("../assets/img/suche.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    width: 20px;
    height: 20px;
    right: 10px;
    top: 25%;
    cursor: pointer;
  }

  .signToggle {
    top: 16% !important;
  }

  .notZIndex {
    z-index: auto !important;
  }

  .mb-top15 {
    margin-top: 15px;
  }

  .modal-header .titleModalHeader {
    font-size: 36px;
    color: #004067;
    font-stretch: normal;
    line-height: 0.89;
    letter-spacing: normal;
    text-align: center;
    margin: auto;
  }

  .modal-header .titleModalHeader-2 {
    font-size: 36px;
    color: $blue;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    margin: auto;
    
    @include md{
      font-size: 22px;
      line-height: 28px;
    }
  }

  .modal-body {
    p {
      width: 405px;
      font-size: 18px;
      margin: auto;
    }

    @include md{
      padding: 0;
    }
  }

  .closeBtnModal {
    position: relative;
  }

  .closeBtnModal img {
    position: absolute;
    top: -40px;
    right: -30px;
  }

  /* Wizard First Step */
  .content_step .content-avatar .customize_avatar {
    max-width: 100%;
  }

  .content_step .content-avatar .logo img {
    max-width: 130px;
  }

  @media (min-width: 576px) {
    .content_step .content-avatar .logo img {
      max-width: 170px;
    }
  }

  @media (min-width: 992px) {
    .content_step .content-avatar .logo img {
      max-width: 100%;
    }
  }

  @media (min-width: 576px) {
    .content_step .content-avatar .customize_avatar {
      max-width: 100%;
    }
  }

  @media (min-width: 768px) {
    .content_step .content-avatar .customize_avatar {
      max-width: 100%;
    }
  }

  @media (min-width: 992px) {
    .content_step .content-avatar .customize_avatar {
      max-width: 100%;
    }
  }

  .content-avatar {
    display: flex;
    margin: auto;
  }

  .avatar-img {
    width: 130px;
  }

  .avatarActive {
    border-radius: 50%;
    cursor: pointer;
  }

  .avatarSelected {
    border: 7px solid #00B6BD;
    border-radius: 50%;
  }

  #glasses {
    border: 5px solid $blue;
  }

  .border-avatar-preview {
    border: 1px solid $blue !important;
  }

  .text-gender {
    color: $blue;
    font-size: 20px;
  }

  /* Resume Profile | Step 4 */
  .bg_resume {
    background: $blue;
  }

  .container-resume-profile {
    background: $blue;
    width: 573px;
    height: 170px;
    position: relative;
    margin: auto;
    border-radius: 26px;
  }

  .container-resume-profile .content-avatar {
    position: absolute;
    left: 60px;
    top: -16px;
  }

  .container-resume-profile .content-avatar img {
    width: 200px;
    height: 200px;
  }

  .container-resume-text {
    margin-top: 15px;
  }

  .text-resume-name {
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    line-height: 32px;
    text-transform: capitalize;
  }

  .text-resume-age {
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    line-height: 22px;
  }

  .truncate-resume-name {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .steps-profile{
    margin-top: 100px;

    input, input.form-control{
      font-family: $vag;
      color: $blue;
      font-size: 20px;
      height: 52px;
      border-radius: 500px;
      padding: 20px;

      &::placeholder{
        color: #CACACA;
      }
    }

    input[type="password"]{
      padding-left: 40px;
      padding-top: 23px;
    }
  }

  .stepwizard-step p {
    margin-top: 10px;
  }

  .stepwizard-row {
    display: table-row;
  }

  .stepwizard {
    display: table;
    width: 50%;
    position: relative;
    margin: auto;
  }

  .stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }

  .stepwizard-row:before {
    top: 17px;
    bottom: 0;
    position: absolute;
    //content: " ";
    width: 100%;
    height: 5px;
    background-color: #95E3E5;
  }

  .stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;

    &:first-child{
      &:before{
        content: none;
      }
    }

    &:last-child{
      &:after{
        content: none;
      }
    }

    &:before {
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      position: absolute;
      content: " ";
      width: 50%;
      height: 5px;
      background-color: $white;
      z-index: -9;
    }

    &:after {
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      position: absolute;
      content: " ";
      width: 50%;
      height: 5px;
      background-color: $white;
      z-index: -9;
    }

    &.step-done{
      &:before, &:after {
        background-color: #95E3E5;
      }

      span{
        color: #95E3E5;
        background-color: #00B7BD !important;
        outline: 0 !important;
      }
    }
  }

  .content-bg-wizard {
    width: 41px;
    height: 41px;
    text-align: center;
    padding: 9px 0;
    font-size: 28px;
    line-height: 25px;
    border-radius: 8px;
    font-weight: 800;
    outline: 0;
  }

  .content-bg-wizard:hover {
    color: #95E3E5;
  }

  .btn-step-wizard-active {
    color: #95E3E5;
    background-color: #00B7BD !important;
    outline: 0 !important;
  }

  .btn-step-wizard-inactive {
    color: #95E3E5;
    background-color: #ffffff;
    outline: 0 !important;
  }

  .nav-link-avatar {
    color: #5d5d5d !important;
  }

  .nav-link-avatar:hover {
    color: #5d5d5d !important;
  }


  svg.avatar-preview {
    position: absolute;
    top: -27px;
    left: 49px;
    width: 200px;
    height: 200px;
  }

  #avatar {
    position: relative;
    margin: auto;
    width: 272px;
    height: 272px;
  }

  #menu {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 99px;
    background-color: #ffffff;
    border-right: 1px solid #707070;
    z-index: 200;
    overflow: hidden;
  }

  #menu_lines div {
    position: absolute;
    left: 10px;
    width: 40px;
    height: 7px;
    background-color: #707070;
    transition: all 0.35s cubic-bezier(0.75, 0.25, 0.1, 0.95);
  }

  #menu.active #menu1 {
    top: 22px;
    transform: rotate(45deg);
    transition: all 0.5s cubic-bezier(0.75, 0.25, 0.1, 0.95);
  }

  #menu.active #menu2 {
    opacity: 0;
    transition: opacity 0.5s cubic-bezier(0.75, 0.25, 0.1, 0.95);
  }

  #menu.active #menu3 {
    top: 22px;
    transform: rotate(-45deg);
    transition: all 0.5s cubic-bezier(0.75, 0.25, 0.1, 0.95);
  }

  .options_div {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .svga-skin-colors,
  .svga-elements-mouths,
  .eyes,
  .eyebrows,
  .hairstyles,
  .facialhairs,
  .clothes,
  .backgroundcolors,
  .glasses,
  .glassopacity,
  .tattoos,
  .accesories,
  .svga-colors-hair,
  .svga-colors-clothes {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .skins,
  .mouths,
  .haircolors,
  .fabriccolors,
  .eyes,
  .eyebrows,
  .hairstyles,
  .facialhairs,
  .clothes,
  .backgroundcolors,
  .glasses,
  .glassopacity,
  .tattoos,
  .accesories {
    flex: 0 0 auto;
    top: 0;
    height: 40px;
    margin: 1px;
    cursor: pointer;
    background-color: #ECF0F1;
    width: calc(100%/7);
  }

  .skins:hover,
  .mouths:hover,
  .eyebrows:hover,
  .hairstyles:hover,
  .haircolors:hover,
  .facialhairs:hover,
  .clothes:hover,
  .fabriccolors:hover,
  .backgroundcolors:hover,
  .glasses:hover,
  .glassopacity:hover,
  .tattoos:hover,
  .accesories:hover {
    border: 1px solid #f04647;
    border-color: #f04647;
  }

  .eyes,
  .eyebrows,
  .mouths,
  .hairstyles,
  .facialhairs,
  .clothes,
  .glasses,
  .tattoos,
  .accesories {
    background-image: url(https://i.imgur.com/q0DdlUI.png);
  }

  .glassopacity {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    height: 43px;
    padding-top: 10px;
    color: #707070;
  }

  .btn-action-avatar {
    color: $blue;
    text-decoration: none !important;
    cursor: pointer;
  }

  #menu_list div {
    width: 100%;
    cursor: pointer;
    padding: 7px 0 7px 0;
    font-size: 19px;
    font-weight: bold;
    color: #707070;
  }

  /* begin Active tabs custom avatar */
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    background-color: #f04647 !important;
    font-weight: bold;
  }

  .nav-tabs .nav-link-avatar.active,
  .nav-tabs .nav-item.show .nav-link {
    color: white !important;
  }

  .nav-tabs .nav-link {
    border-top-left-radius: 0.90rem;
    border-top-right-radius: 0.90rem;
  }

  .nav-tabs {
    border-bottom: 1px solid $blue;
  }

  .tab-content-elements {
    border: 2px solid $blue;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  /* End Active tabs custom avatar */
  /* begin dashboard home student - 1 */
  .bg-sidebar-primary {
    background-color: $blue;
  }

  .bg-main-white {
    background-color: white;
  }

  .border-main-left {
    border-top-left-radius: 40px;
  }

  .sidebar-hg {
    min-height: calc(100vh - 0px);
  }

  .my-avatar-sidebar {
    width: 120px;
    height: 120px;
  }

  .border-avatar {
    border: 4px solid #58afd9;
    border-radius: 50%;
  }

  .container-menu-item {
    width: 74px;
    height: 74px;
    margin: 10px 90px 0px;
    border-radius: 20px;
  }

  .container-menu-item-logout {
    width: 64px;
    height: 64px;
    margin: 10px 90px 0px;
    border-radius: 32px;
  }

  .bg-my-profile {
    background-image: linear-gradient(219deg, #d200ff 90%, #a600ff 5%);
  }

  .bg-my-points {
    background-image: linear-gradient(219deg, #fdd274 90%, #fba640 5%);
  }

  .bg-my-puzzles {
    background-image: linear-gradient(219deg, #f87374 90%, #ef3f40 5%);
  }

  .bg-my-store {
    background-image: linear-gradient(219deg, #00dce0 90%, #00B7BD 5%);
  }

  .bg-logout {
    background-color: #661790;
  }

  .welcome-text {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .nav-link {
    color: #ffffff;
  }

  .nav-link:hover {
    color: #ffffff;
  }

  .nav-link:hover,
  .nav-link:focus {
    outline: 0;
  }

  .text-submenu-sidebar {
    font-size: 18px;
    font-weight: bold;
  }

  .text-right-reserved {
    font-family: $open;
    font-size: 10px;
    font-weight: normal;
  }

  .title-head-section {
    color: $blue;
    font-size: 36px;
    font-weight: bold;

    @include md{
      font-size: 20px;
      line-height: 24px;
    }
  }

  /* bg image div main */
  .bg {
    background-image: linear-gradient(to bottom, #f7f5f557, #f7f5f5db), url("../assets/img/bg_1.jpg");
    background-repeat: no-repeat;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .bg_x2 {
    width: 100%;
    //min-height: calc(100vh - 0px);
    background-image: linear-gradient(to bottom, #f7f5f500, #f7f5f5db), url("../assets/img/bg2.jpg");
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .bg_secondary {
    background-color: $greenLight;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .container-tutorial {
    padding: 10px;
    margin: 25px;

    @include lg{
      padding: 0;
      margin: 0;
    }
  }

  /* Section buy our products online */
  .container-buy-our-products-online {
    width: 100%;
    height: 352px;
    padding: 19.8px 0 0;
    border-radius: 8px;
    background-color: white;
    position: relative;

    .info-buy{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 15px;
    }

    @include md{
      height: 180px;

      .info-buy{
        p{
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  .title-buy-our-products-online {
    font-family: $open;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    color: $blue;
  }

  .item-img-buy-product {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  /* Section buy our products online x2 */
  .container-buy-our-products-online_x2 {
    width: 100%;
    height: 140px;
    padding: 19.8px 0 0;
    border-radius: 26px;
    background-color: white;
    position: relative;
  }

  .container-buy-our-products-online_x2_md{
    height: 240px;
    position: relative;

    .item-img-buy-product_x2{
      top: inherit;
      bottom: 0;
      height: auto;
    }

    .title-buy-our-products-online_x2 {
      position: absolute;
      top: 20px;
      left: 10px;
      height: auto;
      transform: none;
    }

    .buy-arrow-circle-right_x2{
      top: 40px;
      left: inherit;
      height: auto;
      right: 20px;
    }
  }

  .title-buy-our-products-online_x2 {
    width: 100px;
    height: 80px;
    font-family: $open;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    color: $blue;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  .buy-arrow-circle-right_x2 {
    width: 33.1px;
    height: 33px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .item-img-buy-product_x2 {
    height: 219px;
    margin: 17.2px 0 0;
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    top: -63px;
    width: 132px;
    right: 25px;

    @include lg{
      right: 15px;
    }
  }

  .container-buy-our-products-online_x3 {
    width: 100%;
    height: 230px;
    padding: 19.8px 0 0;
    border-radius: 26px;
    background-color: white;
    position: relative;
  }

  .title-buy-our-products-online_x3 {
    width: 181px;
    height: 80px;
    font-family: $open;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    color: $blue;
    position: absolute;
    left: 20px;
    top: 2.5rem;
  }

  .buy-arrow-circle-right {
    @include md{
      width: 20px;
    }
  }

  .buy-arrow-circle-right_x3 {
    width: 33px;
    height: 33px;
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    left: 20px;
    bottom: 2.5rem;
  }

  .item-img-buy-product_x3 {
    height: 130px;
    -o-object-fit: contain;
    object-fit: contain;
    position: absolute;
    top: 25px;
    width: 151px;
    right: 55px;
  }

  .content_ml_mr_shop {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  /* Section learn online with us */
  .container-learn-online-with-us {
    width: 100%;
    height: 352px;
    border-radius: 8px;
    background-image: linear-gradient(to bottom, #f7f5f526, #f7f5f542), url("../assets/img/learn_people_online.png");
    background-size: cover;
    background-position: center;
    position: relative;

    .info-learn{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 15px;
    }

    @include md{
      height: 180px;

      .info-learn{
        p{
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  .title-learn-online-with-us {
    margin-top: 15px;
    font-family: $open;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.05;
    letter-spacing: normal;
    color: white;
    bottom: 50px;

    
  }

  .learn-arrow-right-white {
    
  }

  .item-img-learn-online {
    width: 292px;
    height: 352px;
    -o-object-fit: contain;
    object-fit: contain;
  }

  /* Footer dashboard info institute name */
  .container-institute-detail {
    //position: absolute;
    //width: 100%;
    //height: 94px;
    //right: 0;
    //top: 0;
    background: $greenLight;
    border-radius: 51px;
    position: relative;
  }

  .content-title-name-detail {
    padding: 10px;
  }

  .container-institute-detail,
  .content-title-name-detail h3 {
    color: $blue;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-align: right;
  }

  .container-institute-detail,
  .content-subtitle-name-detail p {
    color: #3F5376;
    font-family: $open;
    font-size: 14px;
    line-height: 10px;
    text-align: right;
    font-weight: normal;
  }

  .container-institute-detail,
  .content-subtitle-info-detail p {
    color: #3F5376;
    font-weight: bold;
    line-height: 0px;
  }

  .container-institute-detail,
  .content-subtitle-info-detail p span {
    color: #3F5376;
    font-weight: normal;
  }

  .logo-info-detail {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .gradient-title {
    background: linear-gradient(180deg, #0A9DFF 0%, #0464FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  /* end dashboard home student - 1 */
  /* begin view my points - 2 */
  .title-head-my-points {
    font-size: 36px;
    font-weight: bold;
    line-height: 0.89;
  }

  .bar_class_container {
    background: #a600ff;
    border-radius: 50px;
    padding: 10px;
  }

  .points_star_left {
    font-size: 24px;
    color: #ffd200;
    vertical-align: -4;
    line-height: 1.23;

    @include lg{
      font-size: 18px;
    }

    @include md{
      span{
        display: none;
      }
    }
  }

  .count_points {
    font-size: 18px;
    color: #ffd200;
    vertical-align: -2;
    line-height: 1.22;
  }

  .points_diamond_left {
    font-size: 24px;
    color: #fff;
    vertical-align: -4;

    @include lg{
      font-size: 18px;
    }

    @include md{
      span{
        display: none;
      }
    }
  }

  .count_diamond {
    font-size: 17px;
    color: #fff;
    vertical-align: -2;
  }

  .diamond-right {
    text-align: right;
    margin-right: 16px;
  }

  .border-middle {
    border-left: 2px solid #8112bd;
  }

  .border-middle-x2 {
    border-left: 2px solid #8112bd;
    height: 32px;
  }

  .puzzle_content {
    background: white;
    border-radius: 40px;
  }

  /* CONTENT CARD PUZZLE */
  .title_puzzle_pending {
    color: #004067;
    font-size: 26px;
    font-weight: 800;
  }

  .title_empty_puzzle_pending {
    color: $blue;
    font-size: 26px;
    font-weight: 800;
  }

  .subtitle_repeat_puzzle {
    color: #002d49;
    font-size: 14px;
    line-height: 1.71;
  }

  .title_name_puzzle {
    color: $blue;
    font-size: 18px;
    font-weight: bold;
  }

  .author_name_puzzle {
    color: #3F5376;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
  }

  .content_section {
    padding: 0 25px;
    border-radius: 40px;
  }

  .content_puzzle {
    height: 300px;
    overflow-x: hidden;
  }

  .avatar-puzzle-item-radius {
    width: 70px;
    border-radius: 14px;
  }

  /* end view my points - 2 */
  /* begin view top ranking - 3 */
  .card_radius_content_x1 {
    border-radius: 26px;
    
    .card{
      border: none;
    }

    .card-body{
      flex: none;
      padding-bottom: 10px;

      h3{
        margin: 0;
      }
    }
  }

  .container_bar_points {
    height: 100%;
    border-radius: 26px;
    background-image: linear-gradient(to bottom, #ffb36f, #ff7c3c);

    .points-left,
    .diamond-right{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .container_my_points {
    padding: 0;
  }

  .container_diamond_progressbar_x1 {
    background: #a600ff;
    border-radius: 26px;
    padding: 10px;
    height: 100%;
    font-family: $vag;

    &:before{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 60%;
      background-color: #8112BD;
      //content: "";
      transform: translate(-50%,-50%);
    }

    @include md{
      img{
        width: 24px;
        height: auto;
      }
    }
  }

  .points_star_left_x2 {
    font-size: 36px;
    color: #ffd200;
    font-weight: bold;
    line-height: 1.23;

    @include md{
      font-size: 18px;
      line-height: 24px;
    }
  }

  .points_diamond_left_x2 {
    font-size: 36px;
    color: #fff;
    font-weight: bold;
    line-height: 0.89;

    @include md{
      font-size: 18px;
      line-height: 24px;
    }
  }

  .progress_img_star {
    width: 60px;
    height: 60px;
    margin-right: 8px;
  }

  .progress_img_diamond {
    width: 60px;
    height: 60px;
    margin-right: 8px;
  }

  .title_head_my_points_ranking {
    color: white;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.17;
    letter-spacing: normal;

    @include md{
      margin: 0;
    }
  }

  .subtitle_head_my_points_ranking {
    color: white;
    font-family: $open;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.17;
    letter-spacing: normal;

    @include md{
      display: none;
    }
  }

  .title-head-top-best {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.23;
    
    @include md{
      font-size: 18px;
      line-height: 24px;
    }
  }

  .tbl_rankin.table th,
  .table td {
    border-top: none !important;
    border-bottom: 1px solid #dee2e6;
  }

  .container_num_rank_position {
    width: 50px;
    height: 50px;
    border-radius: 7px;
    text-align: center;
    padding: 7px 0;
    font-weight: bold;
    color: white;
    font-size: 25px;
    position: relative;
    font-family: $vag;

    @include md{
      width: 30px;
      height: 30px;
      font-size: 21px;
      line-height: 26px;
      padding: 4px 0;
    }
  }

  .card_point_min {
    background: $blue;
    width: 60px;
    height: 60px;
    border-radius: 9px;
    text-align: center;
    padding: 10px 0;
    font-weight: 800;
    color: #fff;
    font-size: 25px;
  }

  .individual_avatar {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    border: 2px solid #005E98;

    @include md{
      height: 30px;
      width: 30px;
    }
  }

  .individual_avatar_name {
    color: #3F5376;
    text-align: center;
    font-weight: normal;
    font-size: 20px;
    margin: 15px 0;
    line-height: 0.9;

    @include md{
      font-size: 12px;
      line-height: 15px;
      margin: 8px 0;
    }
  }

  .title_table {
    color: #ef3f40;
    font-weight: bold;
    font-size: 18px;
    font-family: $vag;
    margin: 0;
    line-height: 1.56;

    @include md{
      font-size: 13px;
      line-height: 16px;
    }
  }

  .table_aling {
    text-align: center;
  }

  .container_bagde_points {
    position: relative;
    margin: 0 auto;
  }

  .container_bagde_points .star_points {
    width: 48px;
    height: 48px;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0;

    @include md{
      width: 30px;
      height: 30px;
    }
  }

  .container_bagde_points .points_winner_badge {
    background: #ffd200;
    border-radius: 60px;
    color: #004067;
    font-weight: bold;
    font-size: 26px;
    margin: 5px 0;
    text-align: right;
    padding: 7px 12px 5px;
    width: fit-content;
    right: 0px;
    line-height: 1.1;
    font-family: $vag;
    padding-left: 58px;

    @include md{
      font-size: 15.3199px;
      line-height: 18px;
      margin: 2px 0;
      text-align: right;
      padding: 5px 10px 3px;
      padding-left: 38px;
    }
  }

  .subtitle_available_gems {
    color: #a600ff;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
    text-align: center;
  }

  .count_available_gems {
    color: #ef3f40;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
    text-align: center;
    text-decoration: underline;
  }

  /* redeem avatar list */
  .container_redeem_avatar_list {
    border: solid 1px #9cd9ff;
    background-color: #eef7ff;
    border-radius: 12px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .avatar_redeem_item {
    width: 80px;
    -o-object-fit: contain;
    object-fit: contain;
    border: 3px solid #a900ff;
    border-radius: 50%;
    margin-bottom: 15px;

    &.avatar_redeem_item_active{
      opacity: 0.6;
    }
  }

  .buy_avatar_redeem_item {
    width: 70px;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .avatar_redeem_head_title {
    color: #a600ff;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
    letter-spacing: normal;
  }

  .avatar_redeem_head_subtitle {
    color: #ef3f40;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
    letter-spacing: normal;
  }

  .spacing_content_points {
    padding: 10px;
  }

  .point_dis {
    color: #FD5C01;
  }

  .card_tile {
    background: #eef7ff;
    border: 1px solid #9cd9ff;
    border-radius: 12px;
  }

  .card_sectios {
    border-radius: 15px;
    margin-bottom: 15px;
    border: none !important;
  }

  .gem_title {
    font-weight: 800;
    color: #005E98;
    font-size: 25px;
    text-align: center;
    letter-spacing: 1;
  }

  .gema_dies {
    font-weight: 800;
    color: #a600ff;
    font-size: 15px;
    text-align: center;
    letter-spacing: 1;
  }

  .avatar_image {
    border-radius: 45px;
    border: 3px solid #a600ff;
  }

  .avatar_title {
    font-weight: 800;
    color: #a600ff;
    font-size: 18px;
    text-align: left;
    letter-spacing: 1;

    @include md{
      font-size: 13px;
      line-height: 16px;
    }
  }

  .avatar_name {
    font-weight: 800;
    color: #ef3f40;
    font-size: 18px;
    text-align: left;

    @include md{
      font-size: 13px;
      line-height: 16px;
    }
  }

  .points_diponible {
    margin: 0 12px;
    width: 100%;
  }

  /* Buy a avatar */
  .container_bagde_points_x2 {
    position: relative;
    margin: 0 auto;
    display: flex;
    width: fit-content;
    margin-bottom: 20px;
  }

  .container_bagde_points_x2 .diamond_redeem_img {
    width: 35px;
    position: relative;
    z-index: 2;
  }
  
  .container_bagde_points_x2 .points_redeem_badge {
    left: -25px;
    background: #ffd200;
    border-radius: 60px;
    color: #005E98;
    font-weight: 800;
    font-size: 15px;
    line-height: 25px;
    margin: 5px 0;
    text-align: right;
    padding: 0 8px;
    position: relative;
    width: fit-content;
    //right: 70;
    z-index: 0;
    font-family: $vag;
    padding-left: 30px;
  }

  .btn-pyment {
    color: #fff;
    width: 80%;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    letter-spacing: 1;
    margin-top: 10px;
  }

  .content_list_scroll {
    position: relative;
    overflow: auto;
    overflow-x: auto;
    width: 100%;
    overflow-x: hidden;
    padding-right: 10px;

    table{
      
        th{
          border: none;
        }

        tbody{
          tr{
            &:last-child{
              td{
                border: none;
              }
            }
          }
        }
      
    }

    &::-webkit-scrollbar {
      width: 12px;
      border-radius: 500px;
    }

    &::-webkit-scrollbar-track {
      background: #EDEDED;
      border-radius: 500px;
    }

    &::-webkit-scrollbar-thumb {
      background: $purple;
      border-radius: 500px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $purple;
    }
  }

  .content_list_scroll_view {
    padding: 5px 10px;
    //height: 500px;
    //overflow: auto;
  }

  /* end view top ranking - 3 */
  /* begin view profile - 3 */
  .logo_k4k_x1 {
    width: 170px;
    height: auto;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .title_head_my_profile {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to bottom, #d200ff, #a600ff);
    margin-bottom: 0;

    @include lg{
      font-size: 32px;
    }

    @include md{
      font-size: 20px;
      line-height: 24px;
    }
  }

  .text-view-tutorial {
    color: $red;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;

    @include md{
      font-size: 13px;
      line-height: 16px;

      img{
        width: 22px;
      }
    }
  }

  .text-view-tutorial:hover,
  .text-view-tutorial:active {
    color: $red;
    outline: none !important;
  }

  .tutorial_play_img {
    width: 35px;
  }

  .container-progressbar-points {
    background: #a600ff;
    border-radius: 50px;
    padding: 10px;
    height: 62px;
    position: relative;
  }

  .container-progressbar-points .content_start_points {
    position: relative;
  }

  .container-progressbar-points .content_start_points .content_description_points {
    position: absolute;
    right: 3px;
    top: -13px;
  }

  .container-progressbar-points .content_start_points .start_points {
    width: 35px;
    position: absolute;
    left: 5px;
    top: -12px;
  }

  .container-progressbar-points .content_start_points .content_description_gems {
    position: absolute;
    right: 10px;
    top: -3px;
  }

  .container-progressbar-points .content_start_points .diamond_gems {
    width: 35px;
    position: absolute;
    left: 5px;
    top: 3px;
  }

  .container_bg_footer {
    //height: 230px;
    border-radius: 16px;
    background-color: white;
    padding: 25px 15px;
    overflow: hidden;
    overflow-x: hidden;
    position: relative;

    @include md{
      padding: 15px;
    }

    .content-institute-detail{
      padding-bottom: 25px;

      @include md{
        padding: 0;
      }
    }

    .slider_container{
      padding-right: 100px;

      @include md{
        padding-right: 70px;
      }
    
    }

    .swiper.slider{
    }

    .carousel-control-next,
    .carousel-control-prev{
      right: 40px;
      position: absolute;
      top: 0;
      margin: 0;
      left: inherit;
      width: 40px;
      height: 40px;

      i{
        width: 30px;
        height: 30px;
        font-size: 18px;
        border: 1px solid #fff;
        padding: 4px 8px;
      }

      @include md{
        right: 20px;
      }
    }

    .carousel-control-next{
      top: inherit;
      bottom: 60px;
    }
    
    .carousel-control-prev{
      top: 60px;
      bottom: inherit;
    }
  }

  .container_bg_footer .title_head_my_avatars {
    color: #004067;
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;

    @include md{
      font-size: 18px;
    }
  }

  .container_bg_footer .title_head_empty_avatars {
    color: #ef3f40;
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
  }

  .container_bg_footer .content-institute-detail .content-logo {
    position: relative;
    text-align: right;
  }

  .container_bg_footer .content-institute-detail .content-logo .logo-institute {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .container_bg_footer .content-institute-detail .content-istitute-description .title-about-institute {
    color: $blue;
    font-weight: bold;
    font-family: $open;
    font-size: 18px;
    line-height: 28px;
    text-align: right;

    @include md{
      font-size: 16px;
      line-height: 20px;
      margin-top: 10px;
    }
  }

  .container_bg_footer .content-institute-detail .content-istitute-description .content-info-detail-institute .title-name-institute {
    color: #3F5376;
    line-height: 0px;
    text-align: right;
    line-height: 0.80;
    font-family: $open;

    @include lg{
      font-size: 14px;
    }

    @include md{
      font-size: 12px;
      line-height: 18px;
    }
  }

  .container_bg_footer .content-institute-detail .content-istitute-description .content-info-detail-institute .text-classroom-teacher {
    color: #3F5376;
    font-weight: bold;
    line-height: 0.80;
    text-align: right;
    font-family: $open;

    @include lg{
      font-size: 14px;
    }
    
    @include md{
      font-size: 12px;
      line-height: 18px;
    }
  }

  .container_bg_footer .content-institute-detail .content-istitute-description .content-info-detail-institute .text-classroom-teacher span {
    color: #3F5376;
    font-weight: normal;
  }

  /* carousel list avatar profile */
  .carousel-x1 {
    height: 220px;
    width: 100%;
  }

  .carousel-x1 .carousel-item {
    text-align: center;
    overflow: hidden;
    height: auto;
  }

  .carousel-x1 .carousel-item {
    padding: 9px;
    box-shadow: 0 6px 20px -6px rgba(0, 0, 0, 0.4);
  }

  .carousel-x1 .carousel-item img {
    margin: 0 auto;
  }

  .carousel-inner {
    height: auto;
  }

  .carousel-control-prev.left {
    margin-left: -45px;
  }

  .carousel-control-next.right {
    margin-right: -45px;
  }

  .carousel-control-next.right-arrow-completed {
    margin-right: -59px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: 50px;
    height: 50px;
    background: none;
  }

  .carousel-control-prev i,
  .carousel-control-next i {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    display: inline-block;
    margin-top: -10px;
    z-index: 5;
    left: 0;
    right: 0;
    color: #fff;
    text-shadow: 0 0 #ba25c3;
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    margin: 5px 5px;
    padding: 7px 10px;
  }

  .carousel-control.left {
    margin-left: -25px;
  }

  .carousel-control.right {
    margin-right: -25px;
  }

  .carousel-control-prev-x2,
  .carousel-control-next-x2 {
    width: 50px;
    height: 50px;
    background: none;
  }

  .carousel-control-prev-x2 i,
  .carousel-control-next-x2 i {
    font-size: 32px;
    text-align: center;
    position: absolute;
    top: 0;
    display: inline-block;
    margin-top: -15px;
    z-index: 5;
    left: 0;
    right: 0;
    color: #fff;
    text-shadow: 0 0 #ba25c3;
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    margin: 5px 5px;
    padding: 4px 10px;
  }

  .carousel-indicators {
    bottom: -40px;
  }

  .carousel-indicators li,
  .carousel-indicators li.active {
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    margin: 1px 4px;
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  .carousel-indicators li {
    background: #999;
    border-color: transparent;
  }

  .carousel-indicators li.active {
    background: #555;
  }

  .container-xl {
    max-width: -webkit-fill-available !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    background: #a600ff;
    border-radius: 85px;
    opacity: 1;
    width: 50px;
    height: 50px;
    margin: 4px 0 4px 15px;
    box-shadow: 0 5px 20px 6px #efd2ff;
    background-image: linear-gradient(to bottom, #d200ff, #a600ff);
  }

  .carousel-control-next-x2,
  .carousel-control-prev-x2 {
    position: relative;
    left: 100%;
    top: -18.5rem;
    background: #a600ff;
    border-radius: 85px;
    opacity: 1;
    width: 50px;
    height: 50px;
    margin: 4px 0 4px 15px;
    box-shadow: 0 5px 20px 6px #efd2ff;
    background-image: linear-gradient(to bottom, #d200ff, #a600ff);
  }

  .control-custom-x1 {
    margin-top: 6.5rem;
  }

  .control-custom-x2 {
    margin-top: 3.6rem;
  }

  .control-custom-x3 {
    margin-top: 1.9rem;
  }

  .img-sections {
    width: 245px;
    height: 245px;
    border-radius: 40px;
  }

  .content-text-positions {
    position: absolute;
    text-align: left;
    left: 20px;
    top: 40%;
    bottom: 50%;
    right: 0;
    color: #fff;
    font-weight: inherit;
    font-size: 20px;
    width: 140px;
    margin: 0px 11px 0;
  }

  .fa-check {
    font-size: 15px;
    color: $blue;
    border: 1px solid;
    border-radius: 26px;
    padding: 5px;
    margin: 4px;
  }

  .fa_function_text {
    width: 35px;
    height: 35px;
    background: #fff;
    padding: 0;
    border-radius: 29px;
  }

  .sections_image {
    width: 169px;
    height: 169px;
    border-radius: 30px;
  }

  .content_name_work {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: #ffffff;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .title_avatar_x1 {
    color: #a600ff;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;

    @include md{
      font-size: 13px;
    }
  }

  .bg_container_avatar_customize {
    background: white;
    border-radius: 16px;
    padding: 15px;

    @include md{
      margin-top: 50px;
      padding: 15px 0px;
    }
  }

  /* carousel list avatar */
  /* end view profile - 3 */
  /* begin view level puzzle - 4 */
  .title_head_level_puzzple {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to bottom, #f87374, #ef3f40);
  }

  .title_pieces_puzzle {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: normal;
  }

  .text_level_puzzle {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
  }

  .card_level_puzzle {
    height: 323px;
    border-radius: 11px;
  }

  /* end view level puzzle - 4 */
  /* end view about the work - 5 */
  .container_about_the_work {
    width: 100%;
    margin-top: 0;
    padding: 15px;
  }

  .container_about_the_work .title_work_name {
    color: #a600ff;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: normal;
  }

  .container_about_the_work .title_author_name {
    color: $blue;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
  }

  .container_about_the_work .container_description_about_work {
    height: 325px;
    max-height: 325px;
    overflow: auto;
    overflow-x: auto;
    overflow-x: hidden;
  }

  .container_about_the_work .container_description_about_work .text_about_work {
    color: #002d49;
    font-family: $open;
    font-size: 13px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 2.46 !important;
    letter-spacing: normal !important;
  }

  .container_about_the_work_quiz {
    width: 100%;
    margin-top: 0;
    padding: 15px;
  }

  .container_about_the_work_quiz .title_work_name {
    color: #a600ff;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: normal;
  }

  .container_about_the_work_quiz .title_author_name {
    color: $blue;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
  }

  .container_about_the_work_quiz .container_description_about_work {
    height: 325px;
    max-height: 325px;
    overflow: auto;
    overflow-x: auto;
    overflow-x: hidden;
  }

  .container_about_the_work_quiz .container_description_about_work .text_about_work {
    color: #002d49;
    font-family: $open;
    font-size: 13px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 2.46 !important;
    letter-spacing: normal !important;
  }

  /* end view about the work - 5 */
  /* begin view my puzzles pending/completed - 6 */
  .title_head_my_puzzles {
    color: #ef3f40;
    margin: 24px 160px 24.5px 0;
    font-size: 48px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
  }

  .title_head_pending {
    color: #004067;
    font-size: 36px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: normal;
  }

  .title_head_completed {
    color: #004067;
    font-size: 36px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: normal;
  }

  .card-img-top {
    border-radius: 30px;
  }

  .cover_puzzle {
    width: 270px;
    height: 270px;
    background-size: cover;
    border-radius: 30px;
    margin: 0 auto;
  }

  .bg_card_none {
    background: none !important;
    border: none !important;
  }

  #carousel_pending {
    height: 386px;
  }

  .title-text-helper-quiz {
    color: #ef3f40;
    font-weight: bold;
    font-size: 16px;
  }

  .title-text-helper-quiz .text-points-extras {
    color: #a600ff;
  }

  .container_my_poinst_progressbar {
    width: 421px !important;
    height: 62px;
    max-width: 421px !important;
    padding: 11px 33px 11px 19px;
    border-radius: 30px;
    background-color: #a600ff;
  }

  .container_my_poinst_progressbar .content-star {
    position: relative;
  }

  .container_my_poinst_progressbar .content-star .star_icon {
    width: 40px;
    position: absolute;
    right: -44px;
  }

  .container_my_poinst_progressbar .content-star .points_star_left {
    position: absolute;
    right: -7.1em;
    line-height: inherit;
  }

  .container_my_poinst_progressbar .content-diamond {
    position: relative;
  }

  .container_my_poinst_progressbar .content-diamond .diamond_icon {
    width: 40px;
    position: absolute;
    right: -16.5em;
  }

  .container_my_poinst_progressbar .content-diamond .points_diamond_left {
    position: absolute;
    right: -15.5em;
    line-height: inherit;
  }

  .container_my_poinst_progressbar:after {
    content: '';
    position: absolute;
    border-right: 2px solid #8112bd;
    width: 32px;
    height: 45px;
    transform: translateX(-50%);
    left: 196px;
    right: 50px;
    top: 24px;
    bottom: 0;
  }

  .container_my_poinst_progressbar_x2 {
    width: fit-content;
    //height: 62px;
    //max-width: 421px !important;
    padding: 11px 33px 11px 19px;
    border-radius: 30px;
    background-color: #A600FF;
    font-family: $vag;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0;

    @include md{
      padding: 8px 15px 8px 15px;

      img{
        width: 30px;
        height: auto;
      }
    }
  }

  .container_my_poinst_progressbar_x2 .divider{
    width: 1px;
    height: 60%;
    background: #8112BD;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20%;
  }

  .container_my_poinst_progressbar_x2 .content-star {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 20px;

    @include md{
      margin-right: 5px;
    }
  }

  .container_my_poinst_progressbar_x2 .content-star .star_icon {
    margin-right: 8px;
    /*width: 40px;
    position: absolute;
    right: -44px;*/
  }
  
  .container_my_poinst_progressbar_x2 .content-star .points_star_left {
    /*position: absolute;
    right: -7.1em;
    line-height: inherit;*/
  }

  .container_my_poinst_progressbar_x2 .content-diamond {
    position: relative;
    display: flex;
    align-items: center;
    text-align: right;
    margin-left: 20px;

    @include md{
      margin-left: 15px;
    }
  }

  .container_my_poinst_progressbar_x2 .content-diamond .diamond_icon {
    margin-right: 8px;
    /*width: 40px;
    position: absolute;
    right: -16.5em;*/
  }

  .container_my_poinst_progressbar_x2 .content-diamond .points_diamond_left {
    /*position: absolute;
    right: -15.5em;
    line-height: inherit;*/
  }

  .container_my_poinst_progressbar_x2:after {
    //content: '';
    position: absolute;
    border-right: 2px solid #8112bd;
    width: 32px;
    height: 45px;
    transform: translateX(-50%);
    left: 50%;
    right: 50px;
    top: 24px;
    bottom: 0;
  }

  /* end view my puzzles pending/completed - 6 */
  /* begin view modal completed quiz */
  .container_text_congratulations {
    position: relative;
  }

  .container_text_congratulations .content_congratulations {
    position: absolute;
    top: 125px;
    left: 0;
    right: 0;
  }

  .text_points_gems {
    color: #ffffff;
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
  }

  .content_audio_track {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 110%;
  }

  /* Only modal go to completed quiz */
  .modal-dialog.modal-dialog-centered.modal-lg.modal_completed_quiz {
    top: 15% !important;
  }

  /* end view modal completed quiz */
  /* begin view modal finish quiz */
  .bg_container_work {
    width: 100%;
    height: 350px !important;
    margin: 0;
    padding: 0;
    border-radius: 48px;
    background-color: #ef3f40;
  }

  .title_have_congratulations {
    color: #ffffff;
    margin: 0 0 16px;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
  }

  .container_work {
    position: relative;
    height: 128px;
  }

  .container_work .container_work_image {
    text-align: center;
    position: absolute;
    top: -104px;
    left: 0;
    right: 0;
  }

  .container_work .container_work_image .work_img {
    width: 210px;
    height: 210px;
    border-radius: 30px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .title_quiz_completed {
    background-image: linear-gradient(to bottom, #f87374, #ef3f40);
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: auto;
  }

  .container_finish_completed_quiz {
    margin: auto;
  }

  .container_finish_completed_quiz .title_work_name {
    color: $blue;
    margin: 0 0 8px;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: normal;
    text-align: center;
  }

  .container_finish_completed_quiz .title_author_name {
    color: $blue;
    font-size: 22px;
    font-family: $open;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
  }

  .container_finish_completed_quiz .container_description_finish_completed_quiz {
    margin: auto;
    text-align: center;
  }

  .container_finish_completed_quiz .container_description_finish_completed_quiz .diamond_purple_img {
    width: 63px;
    height: 63px;
    margin: 32px 113px 4px 112px;
  }

  .container_finish_completed_quiz .container_description_finish_completed_quiz .text_points_extras {
    color: #a600ff;
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: center;
  }

  .text_quiz_bigger {
    color: #ffffff;
    font-size: 85px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.66;
    letter-spacing: normal;
    text-align: center;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
  }

  .title_question {
    font-family: $open;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: $blue;

    &.title_question_error{
      color: $red;
    }
  }

  .author_avatar_work_img {
    width: 139px;
    height: 152px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 30px;
    margin-right: 18px;
  }

  .text_question_correct {
    color: $blue;
    font-family: $open;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
  }

  .text_question_wrong {
    color: #ef3f40;
    font-family: $open;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
  }

  .text_answer_success {
    color: $blue;
    font-family: $open;
    font-size: 14px;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
  }

  .text_answer_failed {
    color: #9c9c9c;
    font-family: $open;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
  }

  .container_congratulations {
    width: 600px;
    margin: auto;
  }

  /* end view modal finish quiz */
  /* quiz questions type text | no picture selectioin */
  .container_quiz_text {
    width: 153px;
    height: 170px;
    border: 1px solid #00558A;
    box-sizing: border-box;
    border-radius: 30px;
    background: #ffffff;
  }

  .container_quiz_text .quiz_answer_text {
    color: $blue;
    font-family: $open;
    padding: 55px 0px;
  }

  /* custom class */
  .wdt-1 {
    width: 220px;
    height: auto;
    -o-object-fit: contain;
    object-fit: contain;
    margin-bottom: 40px;
  }

  .wdt-2 {
    width: 56px;
  }

  .wdt-3 {
    width: 70px;
  }

  .wdt-4 {
    width: 80px;
  }

  .w-btn-1 {
    width: 35% !important;
  }

  .w-btn-2 {
    width: 20% !important;
  }

  .fontSize1 {
    font-size: 28px !important;
  }

  /* color ranking position available */
  .ranking_winner {
    height: 40px;
    width: 40px;
    position: absolute;
    top: -1rem;
  }

  .ranking_x1 {
    background-color: #fba640;
  }

  .ranking_x2 {
    background-color: $blue;
  }

  .ranking_x3 {
    background-color: #ef3f40;
  }

  .ranking_x4 {
    background-color: #a600ff;
  }

  .ranking_x5 {
    background-color: #00e0a1;
  }

  .ranking_x6 {
    background-color: #ffd200;
  }

  .ranking_x7 {
    background-color: #ff388c;
  }

  .mb_x1 {
    margin-bottom: 9rem;
  }

  .line_middle_border_x1 {
    width: 57px;
    height: 2px;
    margin: auto;
    border: 1px solid #979797;
  }

  .correct_answer {
    border: 5px solid #01e0a1;
    box-shadow: 0 5px 11px 0 rgba(141, 198, 200, 0.65);
  }

  .wrong_answer {
    border: 5px solid #f68c8d;
  }

  .btn-next-inactive {
    opacity: 0.4;
  }

  .img-br-1 {
    border: 7px solid #fdbd5a;
  }

  /* Then style the iframe to fit in the container div with full height and width */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 20%;
  }

  .container_main_view {
    //margin-left: 15px;
    //margin-right: 15px;
    padding: 30px;

    @include lg{
      padding: 15px;
    }

    #canvas{
      width: 100% !important;
      //height: auto !important;
    }
  }

  .content_ads_footer {
    width: 100%;
  }

  .img_ads {
    width: 372px;
  }

  /* scrollbar */
  .card_radius_content_x1::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  .card_radius_content_x1::-webkit-scrollbar-track {
    background: #ededed;
  }

  /* Handle */
  .card_radius_content_x1::-webkit-scrollbar-thumb {
    background: #a600ff;
  }

  /* Handle on hover */
  .card_radius_content_x1::-webkit-scrollbar-thumb:hover {
    background: #a600ff;
  }

  .content_puzzle {
    scrollbar-width: 10px;
    scrollbar-color: #a600ff #ededed;
  }

  .card_radius_content_x1 {
    scrollbar-width: thin;
    scrollbar-color: #a600ff #ededed;
  }

  .bg_congratulations {
    background-image: url("./../assets/img/pattern/pattern_congratulations.png");
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
    height: 150px;
  }

  .btn_carousel_item_x1 {
    height: 33px !important;
    width: 75% !important;
  }

  .fixed-bottom-cartoon {
    pointer-events: none;
    z-index: 9999999 !important;
  }

  .bg-vh-x1 {
    height: 100vh;
  }

  .modal.show .modal-dialog {
    top: 0 !important;
  }

  .modal-body p.text_about_work {
    width: 100% !important;
  }

  .modal-body p.quiz_content_answer {
    width: 100% !important;
    font-size: 14px !important;
    font-weight: initial;
  }

  .bg_work_header {
    border-bottom: none;
    padding: 15px 15px 0 15px;
    width: 100%;
    height: 335px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: $border;
    margin: 0;
    padding: 0 !important;
    margin-top: 0 !important;
    overflow: hidden;
    border-bottom-right-radius: $border;
    border-bottom-left-radius: $border;
  }

  .bg_work_header img {
    width: 100%;
    border-top-right-radius: $border;
    border-top-left-radius: $border;
    border-bottom-right-radius: $border;
    border-bottom-left-radius: $border;
    opacity: 0.68;
  }

  /* Tag Audio */
  .controlBar {
    height: 60px !important;
    border-radius: 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
    background-color: #ffffff !important;
  }

  audio {
    width: 460px;
    border-radius: 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
    color: #fafbe4;
    background-color: rgba(255, 255, 255, 0.9);
  }

  audio::-webkit-media-controls-panel,
  video::-webkit-media-controls-panel {
    background-color: #ffffff;
  }

  .pointer {
    cursor: pointer;
  }

  .no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .carousel-container-pending {
    margin: 50px auto;
    min-height: 200px;
    position: relative;
  }

  .carousel-container-completed {
    margin: 50px auto;
    min-height: 200px;
    position: relative;
  }

  .carousel-container-pending .carousel-inner {
    overflow: hidden;
  }

  .carousel-container-completed .carousel-inner {
    overflow: hidden;
  }

  .carousel-container-pending .track {
    display: inline-flex;
  }

  .carousel-container-completed .track {
    display: inline-flex;
  }

  .carousel-inner .carousel-item.active,
  .carousel-inner .carousel-item-start,
  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
    display: flex;
  }

  .card-carousel-container-pending {
    width: 192px;
    flex-shrink: 0;
    padding-right: 15px;
    box-sizing: border-box;
  }

  .card-carousel-container {
    width: 192px;
    flex-shrink: 0;
    height: 250px;
    padding-right: 15px;
    box-sizing: border-box;
  }

  /* Media Queries */
  @media screen and (max-width: 376px) {
    .title_head_my_points_ranking {
      font-size: 26px;
    }

    .subtitle_head_my_points_ranking {
      font-size: 15px;
    }

    /* My Puzzles */
    .cover_puzzle {
      width: 100% !important;
      height: auto !important;
    }
  }

  @media screen and (max-width: 576px) {
    .title_head_my_points_ranking {
      font-size: 26px;
    }

    .subtitle_head_my_points_ranking {
      font-size: 15px;
    }

    /* My Puzzles */
    .cover_puzzle {
      width: 100% !important;
    }

    .card-title-slide {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    .carousel-container-pending {
      width: 80%;
    }

    .carousel-container-completed {
      width: 80%;
    }

    .title_head_my_points_ranking {
      font-size: 26px;
    }

    .subtitle_head_my_points_ranking {
      font-size: 15px;
    }

    /* My Puzzles */
    .cover_puzzle {
      width: 100% !important;
      height: 350px !important;
    }

    .card-title-slide {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1024px) {
    .carousel-container-pending {
      width: 85%;
    }

    .carousel-container-completed {
      width: 85%;
    }
  }

  @media screen and (max-width: 992px) {
    .title_head_my_points_ranking {
      font-size: 26px;
    }

    .subtitle_head_my_points_ranking {
      font-size: 15px;
    }

    .card-title-slide {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1200px) {
    .title_head_my_points_ranking {
      //font-size: 26px;
    }

    .subtitle_head_my_points_ranking {
      font-size: 15px;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {

    /* View: My Puzzles */
    .title_head_my_puzzles {
      font-size: 36px;
    }

    /* Progressbar my points */
    .author_name_puzzle_slide {
      font-size: 14px !important;
    }
  }

  /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
  @media (max-width: 768px) {
    .carousel-inner .carousel-item>div:first-child {
      display: block;
    }

    .author_name_puzzle_slide {
      font-size: 14px !important;
    }
  }

  /* display 4 */
  @media (min-width: 768px) {

    /* View: My Puzzles */
    .title_head_my_puzzles {
      font-size: 36px;
    }

    /* Progressbar my points */
    .author_name_puzzle_slide {
      font-size: 14px !important;
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .container_my_poinst_progressbar {
      max-width: 421px !important;
    }
  }

  /* Extra large devices (large desktops, 1200px and up) */
  .customized-background {
    width: 1200px;
    height: 550px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  @media (max-width: 767.98px) {
    .customized-background {
      width: auto;
      background-position: right center;
    }
  }

.sidebar{
  button{
    background: transparent;
    border: none;
  }
}

.main_login{
  text-decoration: none;

  .box_login_row{
    margin-top: 100px;
  }

  a{
    &:hover{
      color: $blue;
      text-decoration: underline;
    }
  }

  .copyright{
    font-size: 10px;
    font-weight: normal;
  }

  .swiper-pagination{
    .swiper-pagination-bullet{
      border: 1px solid $white;
      background-color:transparent;
      opacity: 1;
      margin: 0 4 px !important;
    }
  }
}

.swiper.login-swiper{
  overflow: hidden;

  &:after{
    content: "";
    background: linear-gradient(51.82deg, rgba(0, 116, 187, 0.567224) 1.98%, rgba(0, 116, 187, 0.0001) 63.56%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9;
  }
}

#sidebarMenu{
  a{
    font-size: 18px;
    font-family: $vag;

    p{
      margin-top: 5px;
    }
    &:hover{
      text-decoration: none;
    }
  }
}

.copy_sidebar{
  font-size: 10px;

  @include lg{
    padding: 0;
  }
}

main{
  background: $blue;
  overflow: hidden;
}

.border_content{
  padding: 30px;
  background: $white;

  -webkit-border-top-left-radius: 50px;
  -moz-border-radius-topleft: 50px;
  border-top-left-radius: 50px;

  @include lg{
    padding: 15px;
  }
}

#FrmProfile{
  input, input.form-control{
    color: #0074BC;
    font-family: $vag;
    margin: 10px 0;
    text-align: center;
    padding: 25px 15px;
    border-radius: 500px;
    font-size: 18px;
  }
}

.swiper-avatar-slider{
  overflow: visible !important;

  .swiper-slide{
    display: flex;
    flex-direction: column;
  }

  .swiper-wrapper{
    overflow: visible;
    .btn-little-small{
      position: absolute;
      top: -15px;
    }

    .buyed{
      background-color: $green;
      color: $white;
      font-weight: bold;
      font-size: 11px;
      border-radius: 500px;
      padding: 4px 8px;
      position: absolute;
      top: -10px;
      z-index: 99;
    }
  }
}

.swiper-incomplete-slider{
  overflow: visible !important;

  .swiper-wrapper{
    overflow: visible;

    .buyed{
      background-color: $green;
      color: $white;
      font-weight: bold;
      font-size: 11px;
      border-radius: 500px;
      padding: 4px 8px;
      position: absolute;
      top: -10px;
      z-index: 99;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.modal-block{
  display: block !important;
}

.modal-none{
  display: none !important;
}

.modal-backdrop{
  pointer-events: none;
  background: rgba(0, 116, 188, 0.714156);
  
  &.show {
    opacity: 1;
  }
}

.modal-content{
  border-radius: 48px;
  -webkit-box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.4); 
  box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.4);
}

.modal-footer,
.modal-header{
  border: none;
  display: block;
}

.puzzle_container{
  h2{
    color: $red;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    margin: 40px 0 20px;
  }

  h3{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 32px;
    color: $blueDark;
    margin: 20px 0;
  }

  audio{
    width: 100%;
  }

  .swiper-slide{
    background: transparent;
  }

  .slider-completed,
  .slider-incomplete{
    position: relative;

    .swiper-slide{
      display: block;
    }

    .carousel-control-prev,
    .carousel-control-next{
      top: 50%;
      transform: translateY(-50%);

      i{
        font-size: 24px;
        padding: 7px 10px;
      }

      @include md{
        width: 30px;
        height: 30px;

        right: -40px;
        position: absolute;
        top: 0;
        margin: 0;
        left: inherit;
        

        i{
          width: 20px;
          height: 20px;
          font-size: 12px;
          padding: 4px 5px;
        }
      }
    }

    .carousel-control-next{
      right: -70px;
    }

    .carousel-control-prev{
      left: -80px;
    }
  }

  .slider-incomplete{
    a{

      img{
        border-radius: 30px;
      }

      h5{
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
        color: $blue;
        margin-top: 15px;
        margin-bottom: 0;

        @include lg{
          font-size: 18px;
        }
      }

      p{
        color: $blueDark;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;

        @include lg{
          font-size: 12px;
        }
      }

      &:hover{
        text-decoration: none;
      }
    }
  }

  .slider-completed{
      .carousel-control-next{
        top: 60%;
        transform: translateY(-60%);
      }

      .carousel-control-prev{
        top: 60%;
        transform: translateY(-60%);
      }
    

    .container_completed{
      position: relative;
      cursor: pointer;

      .content_img{
        width: 170px;
        height: 170px;
        border-radius: 30px;
        overflow: hidden;
        position: relative;

        &:before{
          content: " ";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, #0A9DFF 0%, #0464FF 100%);
          opacity: 0.8;
        }

        @include md{
            width: 110px;
            height: 110px;
        }
        
      }
      .content_completed{
        position: absolute;
        text-align: left;
        padding: 15px;
        top: 35px;
        left: 0;
  
        h5{
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: $white;
          margin-top: 10px;
          margin-bottom: 0;
        }
  
        &:hover{
          text-decoration: none;
        }

        @include md{
          top: 0;

          h5{
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }
  }

  @include md{
    h2{
      font-size: 28px;
      line-height: 24px;
    }

    h3{
      font-size: 22px;
      line-height: 26px;
    }

    .slider-completed,
    .slider-incomplete{
      a{
        h5{
          font-size: 13px;
          line-height: 16px;
        }

        p{
          display: none;
        }
      }
    }
  }

}

.modal {
  overflow-y:auto;

  .modal-content{
    border-radius: $border;
  }

  &#modalNotGems,
  &#modalSuccessEmail,
  &#modalRememberPassword{
    .modal-content{
      padding: 15px;
    } 
  }

  &#modalRememberPassword{
    .btn-close{
      position: absolute;
      right: 10px;
      top: 20px;
    } 
  }

}

#modalAboutTheWork, #modalCompletedQuiz{
  a[type="button"]{
    z-index: 9;
  }

  .bg_work_header{
    height: 400px;
  }

  .btn-close{
    width: 60px;
    position: absolute;
    right: -20px;
    top: -20px;
  }

  p{
    font-size: 13px;
    line-height: 32px;
    margin-bottom: 1.5rem;
  }

  .divider{
    background: #979797;
    height: 1px;
    margin: 0 15px;
  }

  .container_description_about_work{
    padding-right: 30px;

    &::-webkit-scrollbar {
      width: 9px;
      border-radius: 500px;
    }

    &::-webkit-scrollbar-track {
      background: #EDEDED;
      border-radius: 500px;
    }

    &::-webkit-scrollbar-thumb {
      background: #A6E2E4;;
      border-radius: 500px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $purple;
    }
  }
}

#modalQuestionsQuiz{
  .question_options{
    a{
      //border: 2px solid transparent;
      display: block;
      border: none;
      background: transparent;
      width: 100%;
      height: 100%;
      position: relative;

      &:focus{
        outline: none;
      }

      .text-option{
        height: 100%;
        color: $blue;
        border: 2px solid $blue;
        box-sizing: border-box;
        border-radius: 35px;
        padding: 55px 10px;
        position: relative;
        z-index: 9;
        background: $white;
      }

      img{
        margin: 0;
        width: 100%;
        height: auto;
        z-index: 9;
        position: relative;
      }

      .image_container{
        position: relative;
        display: block;

        &:after{
          content: "";
          position: absolute;
          width: 106%;
          height: 106%;
          //background: #0bd599;
          top: -3%;
          left: -3%;
          border-radius: 48px;
          z-index: 0;
        }
      }

      &:hover{
        .image_container{
          &:after{
            background: $blue;
          }
        }
      }

      &.selected{
        .image_container{
          &:after{
            background: #0bd599;
          }
        }
        
        .text_container{
          .text-option{
            border: 2px solid #0bd599;
            box-shadow: 0px 10px 30px 0px rgba(11,213,153,0.2);
            -webkit-box-shadow: 0px 10px 30px 0px rgba(11,213,153,0.2);
            -moz-box-shadow: 0px 10px 30px 0px rgba(11,213,153,0.2);
          }
        }
      }
    }
  }
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

canvas{
  position: relative;
  z-index: 999999999 !important;
}

.html5-puzzle {
  font-family: Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /********************/
  /* board background */
  /********************/
  border: 1px solid #aaa;
}

.html5-puzzle div,
.html5-puzzle ul,
.html5-puzzle li,
.html5-puzzle canvas { margin: 0; padding: 0; }

.hide { display: none; }

.clear { clear: both; }

/*== Jigsaw puzzle ==*/
div#canvas-wrap {
  width: 100%;
  height: auto;
  background: $white;
  position: relative;
  z-index: 0 !important;
  border-radius: $border;
}

canvas#canvas, canvas#buffer, canvas#image-preview {
  position: absolute;
  top: 0px;
  left: 0;
}

canvas#canvas{
  border-radius: $border;
}

canvas#canvas { 
  z-index: 100;
  position: relative;
}

.loading  canvas { visibility: hidden; }

canvas#buffer { z-index: 70; }

canvas#image-preview.show {
  left: 50%; 
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  
  
}

/*== Options bar ==*/
div#game-options ul {
  margin: 0;
  padding: 0;
}
div#game-options {
  z-index: 500;
  padding: 0;
  width: 100%;
  margin-top: 30px;

  @include md{
    ul{
      li{
        img{
          width: 50px;
        }
      }
    }
  }
}

#JIGSAW_SHUFFLE,
#SHOW_PREVIEW{
  width: 63px;
  height: 67px;
  margin-right: 20px;

  @include md{
    display: none !important;
  }
}

#game-options{
  @include md{
    //display: none;
  }
}

div#game-options ul li { 
  float: left;
  list-style: none;
  line-height: 25px;

  a{
    display: inline-block;
    background-size: cover;
  }

  &.time_block{
    float: none;
    position: relative;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: $white;
    font-family: $vag;

    span{
      background: linear-gradient(226.16deg, #006A95 8.52%, #00385B 96.01%);
      border: 4px solid #0074BC;
      box-sizing: border-box;
      border-radius: 9px;
      color: #00F2CF;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 32px;
      padding: 8px 30px 8px 12px;
      position: relative;
    }

    b{
      background: linear-gradient(180deg, #F87374 0%, #EF3F40 100%);
      border: 2px solid #FF8788;
      box-sizing: border-box;
      border-radius: 9px;
      padding: 10px;
      margin: 0;
      display: inline-block;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 32px;
      position: relative;
      left: -15px;
    }

    @include md{
      span{
        font-size: 20px;
        line-height: 28px;
        padding: 8px 30px 8px 12px;
        position: relative;
      }
  
      b{
        padding: 10px;
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}

div#game-options ul li b {
  margin: 0 auto;
}

/*== Select ==*/
div#game-options div.styled-select select {
  outline: none;
  background: transparent;
  width: 125px;
  padding: 5px;
  font-size: 13px;
  border: none;
  height: 28px;
  -webkit-appearance: none;
}

div#game-options div.styled-select {
  width: 100px;
  height: 28px;
  margin-right: 10px;
  overflow: hidden;
  border: 1px solid #333;
}

/*== Share buttons ==*/
#share-twitter, #share-facebook {
  text-indent: -10000px;
  width: 32px;
  height: 32px;
  display: block;
  cursor:pointer;
}

/*== Crear puzzle ==*/
#image-error { color: red; margin: 0 10px; display: none; }
#dnd { display: none }

#game-options #create {
  display: none;
  right: 25px;
  position: absolute;
}

#game-options #create .add {
  //background: url(../images/plus.png)  10px 6px no-repeat #f3f3f3;
  padding-left: 30px;
}
/*== Modal window ==*/

div#congrat { text-align: center; }

div#help {
  font-size: 15px;
}

div#help ul { margin-left: 10px; }

/*== mobile ==*/
@media screen and (max-width: 960px) {
  div#game-options { left: -30px; }
  #SHOW_EDGE, #SHOW_MIDDLE, #SHOW_ALL,#SHOW_FILEPICKER { display: none; }
}

@media screen and (max-width: 479px) {
  #SHOW_HELP { display: none; }
  #JIGSAW_SHUFFLE { margin-left: 15px; }
  #SHOW_PREVIEW { margin-right: 10px; }
}

.container-diamond-slider{
  position: relative;
}

.overflow-hidden{
  overflow: hidden !important;
}

.shop{
  h2{
    color: $red;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    margin: 40px 0 20px;
  }

  h3{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 32px;
    color: $blueDark;
    margin: 20px 0;
  }

  .blue-arrow-down{
    font-family: $vag;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 34px;
    display: block;
    cursor: pointer;
    color: $blue;
    outline: none;
    border: none;
    background: transparent;

    span{
      position: relative;
      top: -5px;
    }
  }

  .container-slider-puzzle,
  .container-slider-avatar{
    position: relative;

    .carousel-control-prev,
    .carousel-control-next{
      transform: translateY(-40);
      top: 40%;
      position: absolute;
    }

    .swiper-slide{
      display: block;
      background: transparent;

        img{
          border-radius: 30px;
        }
  
        h5{
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 32px;
          color: $blue;
          margin-top: 15px;
          margin-bottom: 0;

          @include lg{
            font-size: 18px;
            line-height: 28px;
          }
        }
  
        p{
          color: $blueDark;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
        }
  
        &:hover{
          text-decoration: none;
        }
      
    }
  }

  @include md{
    h2{
      font-size: 20px;
      line-height: 20px;
    }

    h3{
      font-size: 16px;
      line-height: 20px;
      margin-top: 0;
    }

    p{
      display: none;
    }

    .container-slider-avatar,
    .container-slider-puzzle{
      @include md{
        padding: 0;
      }

      .carousel-control-prev,
      .carousel-control-next{
        transform: translateY(-30%);
        top: 30%;
        position: absolute;

        width: 30px;
        height: 30px;
        border-radius: 500px;

        i{
          border-radius: 500px;
          width: 20px;
          height: 20px;

          font-size: 14px;
          padding: 3px 2px;
        }
      }

      .carousel-control-prev{
        left: -35px;
      }
      .carousel-control-next{
        right: -35px;
      }
      
      .swiper-slide{

        .btn{
          width: 100% !important;
        }

        .content-shop{
          display: flex;

          .shop-left{
            img{
              width: 50px;
              border-radius: 10px;
            }
          }
          
          .shop-right{
            padding-left: 5px;
            text-align: left;
          }

          h5{
            margin: 0;
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }

    .blue-arrow-down{
      font-size: 13px;
      line-height: 16px;

      img{
        width: 48px;
        height: 48px;
      }
  
      span{
        position: relative;
        top: -5px;
      }
    }
  }
}

.home_puzzle{
  background: $white;
  padding: 24px 40px;
  border-radius: $border;
  border: 1px solid #DEDEDE;
  margin-top: 25px;

  h4{
    color: $blueDark;
    margin-bottom: 20px;
  }

  h5{
    color: $blue;
    margin-bottom: 0;
  }

  p{
    margin-bottom: 0;
  }

  .puzzle_item{
    cursor: pointer;
    margin-bottom: 15px;
  }

  &.home_puzzle_empty{

    h4{
      color: $blue;
    }

    .icon_pending{
      margin-bottom: 15px;
    }
  }

  @include md{
    padding: 14px 20px;
    border-radius: 25px;

    h4, p{
      display: none;
    }

    h5{
      font-size: 13px;
      line-height: 16px;
    }
  }
}

$image-bg: gray;
$image-border: white;
$image-border-current: aqua;

.radio-img{
  width: 100%;
}

.image {
  width: 100%;
  height: auto;
  background-position: center center;
  background-color: $image-bg;
  display: inline-block;
  margin: 10px;
  background-size: cover;
  border-radius: $border;
  position: relative;
  
  img{
    z-index: 9;
    position: relative;
    border-radius: 30px;
    width: 100%;
  }

  &.replace_with_text{
    .text_option{
      height: 100%;
      color: $blue;
      border: 2px solid $blue;
      box-sizing: border-box;
      border-radius: 35px;
      padding: 60px 10px;
      position: relative;
      z-index: 9;
      background: $white;
    }
  }

  &:hover {
    opacity: 1;
  }
}

.radio-img {
  >input {
    display: none;
  }

  >.image {
    cursor: pointer;
    //border: 2px solid red;

    &:after{
      //background: #0bd599;
      content: "";
      position: absolute;
      width: 106%;
      height: 106%;
      top: -3%;
      left: -3%;
      border-radius: 48px;
      z-index: 0;
    }
  }

  >input:checked+.image {
    //border:2px solid orange;
    &:after{
      background: #0bd599;
    }
  }

}

.man{
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999999;
  pointer-events: none;

  @include lg{
    width: 360px;
  }

  @include md{
    width: 170px;
    left: inherit;
    right: 0;
  }
}

.toggleLayout{
  position: fixed;
  left: 0;
  bottom: 60px;
  width: 20px;
  height: 35px;
  background: $blue;
  z-index: 99;
  cursor: pointer;

  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  i{
    color: $white;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-40%, -50%);
  }
}

#sidebarMenu{
  position: relative;
  z-index: 0;
  display: none !important;

  &.sidebar-opened{
    display: block !important;
  }

  .border-avatar{
      width: 122px;
      height: 122px;
  }

  .steps{
      display: block;
      width: fit-content;

      .nav-link{
          text-align: center;

          .btn_logout{
              background: $purple;
              width: 64px;
              height: 64px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 auto;
              border-radius: 500px;
              position: relative;
              z-index: 0;
              margin-bottom: 8px;
          }

          .btn_sidebar{
              width: 74px;
              height: 74px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 auto;
              border-radius: 20px;
              position: relative;
              z-index: 0;
              margin-bottom: 8px;

              img{
                  position: relative;
                  z-index: 2;
                  bottom: 5px;
              }

              &:before{
                  content: '';
                  position: absolute;
                  bottom: 5px;
                  width: 100%;
                  height: 100%;
                  border-radius: 20px;
                  z-index: 1;
              }

              &.btn_profile{
                  background: #8100C5;
                  &:before{
                      background: linear-gradient(218.7deg, #D200FF 10.45%, #A600FF 95%);
                  }
              }

              &.btn_points{
                  background: #DB7E0E;
                  &:before{
                      background: linear-gradient(180deg, #FDD274 0%, #FBA640 100%);
                  }
              }

              &.btn_puzzle{
                  background: #CB2E2F;
                  &:before{
                      background: linear-gradient(180deg, #F87374 0%, #EF3F40 100%);
                  }
              }

              &.btn_shop{
                  background: #009DA3;
                  &:before{
                      background: linear-gradient(180deg, #00DCE0 0%, #00B7BD 100%);
                  }
              }
          }
      }
      
      &:hover{
          .nav-link{
              .btn_sidebar{
                  img{
                      bottom: 0;
                  }
  
                  &:before{
                      bottom: 0;
                  }
              }
          }
      }
  }

  @include lg{
    .border-avatar{
      width: 48px;
      height: 48px;
    }

    .my-avatar{
      padding: 0;
      text-align: center;
      .welcome-text{
        display: none;
      }
    }

    .steps{
      .nav-link{
        padding: 10px 0;
          p{
            display: none;
          }

          .btn_logout{
            width: 48px;
            height: 48px;
            border-radius: 10px;
          }

          .btn_sidebar{
            width: 48px;
            height: 48px;
            border-radius: 10px;

            &:before{
              border-radius: 10px;
            }

            img{
              width: 35px;
              height: auto;
            }
          }
      }
    }
  }
}

.rotate_section{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/bg2.jpg");
  z-index: 99999999;

  .rotate_content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: fit-content;
    text-align: center;

    .rotate_icon{
      background: linear-gradient(180deg, #0A9DFF 0%, #0464FF 100%);
      padding: 20px;
      border-radius: 500px;
      width: fit-content;
      margin: 0 auto;
    }

    h3{
      color: $blue;
      margin-top: 20px;
      font-family: $vag;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
    }
  }
}

a{
  cursor: pointer;
}

.position-relative{
  position: relative !important;
}

.skeleton{
  position: relative;

  svg{
    width: 100%;
  }

  &.skeleton-table{
    td{
      vertical-align: middle;
    }

    .card_tile{
      background: #fafafa;
      border: 1px solid #f9f9f9;
    }
  }

  &.skeleton-image{
    position: relative;

    &.skeleton-image-center{
      margin: 0 auto;
    }
  }

  .skeleton-item{
    display: flex;
    width: 100%;
  }
}

.border-radius-30{
  border-radius: 30px;
  overflow: hidden;
}

.screen_points{
  .card{
    padding: 20px 10px;
  }
}

.table-points{
  tbody{
    tr{
      &:hover{
        background: rgba(166, 0, 255, 0.1);
      }

      td{
        
      }
    }
  }
}

.error{
  color: $red
}

.color-black{
  color: $blueDark!important;
}

.custom-image{
  border-radius: $border;
}

body{
  iframe:last-of-type { 
    pointer-events: none;
  }
}

#svgAvatars{
  #svga-footermenu{
    border: none;
    padding: 5px;

    #svga-saveavatar,
    #svga-savenewavatar{
      background-color: $purple;
      font-family: $vag;
      font-size: 18px;
      border-radius: $border;
      width: 45%;

      span{
        font-weight: 700;
        color: $white;
      }

      &:hover{
        background-color: $blue;
      }
    }

    #svga-savenewavatar{
      background-color: $blue;
      &:hover{
        background-color: $purple;
      }
    }
  }
}

.buttons{
  display: flex;
  .btn{
    margin-right: 5px;
  }
}

.loading_avatar{
  justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: $white;
    align-items: center;
    display: flex;
}

.color-red{
  color: $red !important;
}

.container_error_message{
  background-color: rgba($red, 0.2);
  border: 1px solid $red;
  border-radius: 500px;
  padding: 0px 15px 4px;
}